<template>
  <page-wrapper :isLoading="isLoading">
    <template #title>Candle Trades Details</template>
    <custom-table
      v-if="tradesList"
      :pageNumber="pageNumber"
      :count="count"
      :totalCount="totalCount"
      :tableItems="tradesList"
      :tableColumns="myTableColumns"
      @changePage="changePage"
    >
      <template #userName="data">
        <b-link :to="`/apps/users/edit/${data.item.userInfo.userId}`">
          {{ data.item.userInfo.userName }}
        </b-link>
      </template>
      <template #type="data">
        <b-badge :variant="data.item.type == 1 ? 'success' : 'danger'">
          {{ data.item.type == 1 ? "UP" : "DOWN" }}
        </b-badge>
      </template>
      <template #state="data">
        <b-badge :variant="data.item.state == 1 ? 'success' : 'danger'">
          {{ data.item.state == 1 ? "WIN" : "LOSE" }}
        </b-badge>
      </template>
    </custom-table>
  </page-wrapper>
</template>
<script>
export default {
  title: "Candle Details",
  mounted() {
    this.getAllCandleTrades();
  },
  data() {
    return {
      isLoading: false,
      pageNumber: 1,
      count: 10,
      totalCount: null,
      tradesList: null,
      myTableColumns: [
        {
          key: "userName",
          label: "UserName",
          formatter: (value, label, data) => data.userInfo.userName,
        },
        {
          key: "userFullName",
          label: "User Full Name",
          formatter: (value, label, data) =>
            `${data.userInfo.name} ${
              data.userInfo.familyName ? data.userInfo.familyName : "-"
            }`,
        },
        {
          key: "tradeId",
          label: "Trade Id",
        },
        {
          key: "market",
          label: "Market",
        },
        {
          key: "type",
          label: "Up/Down",
          formatter: (value) => {
            if (value == 1) {
              return "UP";
            }
            return "DOWN";
          },
        },
        {
          key: "amount",
          label: "Amount",
          formatter: (value) =>
            `${Intl.NumberFormat("en-US").format(parseFloat(value))} $`,
        },
        {
          key: "createDate",
          label: "Create Date",
          formatter: (value) => {
            return `${value.slice(0, 10)} - ${value.slice(11, 19)}`;
          },
        },
        {
          key: "state",
          label: "State",
          formatter: (value) => {
            if (value == 1) {
              return "PENDING";
            } else if (value == 2) {
              return "WIN";
            } else {
              return "LOSE";
            }
          },
        },
      ],
    };
  },
  methods: {
    changePage(pageNumber) {
      this.pageNumber = pageNumber;
      this.getAllCandleTrades();
    },
    async getAllCandleTrades() {
      try {
        this.isLoading = true;
        let _this = this;
        let tradeGetAllRequest = new TradeGetAllRequest(_this);
        tradeGetAllRequest.setParams({
          candleId: _this.$route.params.id,
          pageNumber: _this.pageNumber,
          count: _this.count,
        });
        await tradeGetAllRequest.fetch((res) => {
          _this.totalCount = res.tradesCount;
          _this.tradesList = res.trades;
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    PageWrapper,
    CustomTable,
    BBadge,
    BLink,
  },
};
import PageWrapper from "@/views/components/Utilities/PageWrapper.vue";
import CustomTable from "@/views/components/Utilities/CustomTable.vue";
import { BBadge, BLink } from "bootstrap-vue";
import { TradeGetAllRequest } from "@/libs/Api/Trade";
</script>
